import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/environments/settings';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { User } from 'src/app/core/interfaces/user.interface';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserProfileService {
  private apiUrl: string

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }
  
  getMyProfile(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/app/appUsers/profile`);
  }

  updateMyProfile(payload: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/app/appUsers/profile`, payload);
  }

}