import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { UpdateUserFullName } from "src/app/features/users/state/users.actions";
import { UserProfileService } from "../services/user-profile.service";
import { LoadMyProfile, UpdateProfile } from "./user-profile.actions";
import { UserProfileStateModel } from "./user-profile.model";

@State<UserProfileStateModel>({
  name: "userProfile",
  defaults: {
    loading: false,
    saving: false
  }
})
@Injectable()
export class UserProfileState {
  
  constructor(
    private userProfileService: UserProfileService
  ) {}

  @Selector() static loading(state: UserProfileStateModel) { return state.loading; }
  @Selector() static saving(state: UserProfileStateModel) { return state.saving; }
  @Selector() static user(state: UserProfileStateModel) { return state.user; }
  
  @Action(LoadMyProfile)
  loadMyProfile(ctx: StateContext<UserProfileStateModel>) {
    ctx.patchState({
      loading: true
    });
    
    return this.userProfileService.getMyProfile().pipe(
      tap(
        (response) => {
          ctx.patchState({
            user: response,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({
            loading: false
          });
        }
      )
    );
  }
  
  @Action(UpdateProfile)
  updateProfile(ctx: StateContext<UserProfileStateModel>, action: UpdateProfile) {
    ctx.patchState({
      saving: true
    });
    
    return this.userProfileService.updateMyProfile(action.payload).pipe(
      tap(
        () => {
          ctx.dispatch(new UpdateUserFullName(`${action.payload.firstName} ${action.payload.lastName}`));
          ctx.patchState({
            saving: false
          });
        },
        (error) => {
          ctx.patchState({ saving: false });
        }
      )
    );
  }
}
